import Nav from './Nav'
import './Footer.css'

function Footer(){
    return( 
        <footer>
            <Nav id="nav-inferior"/>
            <div>
                <p id='marca-copy'>Página realizada por natalia cortes ©</p> 
            </div>
        </footer>
    );
}

export default Footer